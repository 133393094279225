import React from "react"
import { Link } from "gatsby"

export default function ImageCard(props) {

    const linkHref = props.href || undefined;

    return (
        <div className="card shadow-sm image-card h-100">
        <img src={props.image} className={props.imageClass} />
        <div className="card-body">
          <p className="card-text">{props.children}</p>
          { linkHref &&
            <div className="d-flex justify-content-between align-items-center">
              <div className="btn-group">
                <Link to={linkHref} className="btn btn-sm btn-outline-secondary">View</Link>
              </div>
            </div>
          }
        </div>
      </div>

    );
}