import React from "react"
import ImageCard from "../../components/image-card";
import Layout from "../../components/layout"

export default function TeamGirlsU16() {
    
  return ( 
    <Layout>
    <section className="container">
    <h1>Girls Under 16's</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
                <img src="/girls-u16.png" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
                <p>The Under 16 Girls team play in the West Riding Girls Football League. The team is run in a supportive fun environment with the overall goal of helping players improve their skills, build confidence and make new friends.</p>
                <p>Every team has Managers, Coaches or Assistants who are either FA or UEFA qualified. In addition all staff are CRC checked with at least one First Aid qualified member present at all times.</p>
            </div>
            <div className="row">
                <div className="col">
                    <ImageCard image="/girls-u16a.png" imageClass="object-fit-cover">Flood light training</ImageCard>
                </div>
                <div className="col">
                    <ImageCard image="/girls-u16b.png" imageClass="object-fit-cover">Roller blading as a group</ImageCard>
                </div>
                <div className="col">
                    <ImageCard image="/girls-u16c.png" imageClass="object-fit-cover">Playing in the snow on all weather pitch</ImageCard>
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 11, DoB 01/09/2006 - 31/08/2007</dd>
                <dt>Manager(s)</dt>
                <dd>Kieran Brady</dd>
                <dt>Training</dt>
                <dd>Wednesday afternoons (please contact for times)</dd>
                <dt>League</dt>
                <dd>Westriding Girls League. Matches: Saturday Mornings</dd>
                <dt>Join the team</dt>
                <dd>
                    <a href="mailto:kieran.brady@transunion.co.uk" target="_blank" rel="noopener noreferrer">kieran.brady@transunion.co.uk</a><br></br>
                </dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsor-copiercompany.png" />
                <div className="card-body">
                    <p className="card-text">OVER 100 YEARS EXPERIENCE IN THE COPIER INDUSTRY</p>
                    <div className="btn-group">
                    <a href="https://copiercompany.co.uk/" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                    </div>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

